<template>
  <el-dialog
    v-elDragDialog
    width="550px"
    :title="isCreated ? $t('newadd') : $t('edit')"
    :close-on-click-modal="false"
    :visible.sync="show"
  >
    <el-form
      :model="params"
      :rules="rules"
      size="small"
      ref="form"
      label-width="120px"
      v-loading="loading"
    >
      <el-form-item :label="$t('organManagement.parentOrgan')" v-if="parentOrgId">
        {{ parentOrgName }}
      </el-form-item>
      <el-form-item :label="$t('organManagement.orgName')" prop="orgName">
        <el-input v-model.trim="params.orgName" :placeholder="$t('organManagement.enterOrgName')" />
      </el-form-item>
      <el-form-item :label="$t('organManagement.remark')" prop="remark">
        <el-input v-model.trim="params.remark" :placeholder="$t('organManagement.enterRemark')" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="submitEvent" v-loading="btnLoading">{{
        $t('submit')
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
export default {
  mixins: [dialogCommonParams],
  props: ['parentId'],
  data() {
    return {
      btnLoading: false,
      parentOrgId: this.currentItem ? this.currentItem.parentId : this.parentId,
      parentOrgName: '',
      params: {},
      rules: {
        orgName: [{ required: true, message: this.$t('organManagement.enterOrgName') }]
      }
    }
  },
  created() {
    if (!this.isCreated) {
      this.params = this.$utils.deepClone(this.currentItem)
    }
    if (this.parentOrgId) {
      this.getParentOrgName()
    }
  },
  methods: {
    //获取上级机构名称
    async getParentOrgName() {
      try {
        this.loading = true
        const params = { id: this.parentOrgId }
        const res = await this.$api.systemManagement.orgGetById(params)
        if (res.result?.orgName) {
          this.parentOrgName = res.result.orgName
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    async submitEvent() {
      try {
        const valid = await this.$refs.form.validate()
        if (!valid) return
        this.btnLoading = true
        const params = { ...this.params, parentId: this.parentOrgId }
        await this.$api.systemManagement.orgSave(params)
        this.$message.success(this.isCreated ? this.$t('newSuccess') : this.$t('editSuccess'))
        this.$emit('getData')
        this.show = false
      } catch (error) {
        console.log(error)
      } finally {
        this.btnLoading = false
      }
    }
  }
}
</script>
